<template>
<div>
  <template>
    <div id="breadcrumb-alignment" class="mb-1">
      <div class="d-flex justify-content-start breadcrumb-wrapper">
        <b-breadcrumb
          :items="breadCrumb()"
        />
      </div>
    </div>
  </template>
  <b-row class="match-height">
    <b-col
      lg="12"
      md="12"
    >
   
    <b-card
      title="Add Question">
      <b-alert
        v-model="showDismissibleAlert"
        variant="danger"
        dismissible
        class="mb-1"
      >
        <div class="alert-body">
          {{error_message}}
        </div>
      </b-alert>
      
      <b-form @submit="formSubmit">
        
        <b-row>
          
          <b-col md="12">

            <b-row>
              <b-col md="12" >
                
                <b-form-group
                  label="Project Site"
                  class="required"

                >
                  <!-- <b-form-select v-model="form.site">

                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    
                    <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
                    
                    
                  </b-form-select> -->
                  <v-select
                      v-model="temp_site"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      multiple
                      label="site_name"
                      :options="sites"
                      placeholder="Select"
                      :close-on-select=false
                            :clear-on-select=false
                  >

                  <template #selected-option="{ site_name }">
                    <span v-if="site_name.length < 23">{{site_name}}</span>
                    <span v-if="site_name.length >= 23">{{site_name.substring(0, 23) + '...'}}</span>
                  </template>

                </v-select>
                    
                </b-form-group>
              </b-col>

            </b-row>

            <b-row>
              <b-col md="12">
                <b-form-group
                  label="Question (en)"
                  class="required"
                >
                  <b-form-input
                    placeholder="3 to 100 characters"
                    v-model="form.en_question"
                  />
                </b-form-group>
              </b-col>

              
            </b-row>

            <b-row>
              <b-col md="12">
                <b-form-group
                  label="Question (ch)"
                  class=""
                >
                  <b-form-input
                    placeholder="3 到 100 个字符"
                    v-model="form.zh_question"
                  />
                </b-form-group>
              </b-col>

              
            </b-row>
            
            
            <b-row>
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="warning"
                  class="mr-1"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="$router.push({ name:'safety-inspection-questions' })"
                >
                  Cancel
                </b-button>
              </b-col>
          </b-row>


          </b-col>
        </b-row>

      </b-form>
    </b-card>
    </b-col>
  </b-row>

  
</div>
</template>

<script>

import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BFormFile,BBreadcrumb
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import vSelect from 'vue-select';

export default {
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BFormText,
    BInputGroupPrepend,
    BInputGroup,
    BMedia,
    BAvatar,
    BTable,
    BModal,
    VueCropper,
    BFormRadio,
    BFormTimepicker,
    VueTimepicker,
    vSelect,
    BFormFile,
    BBreadcrumb
  },
  directives: {
    Ripple,
  },
  data() {
    return {

      temp_site: [],
      error_message:null,
      showDismissibleAlert:false,

      form :{
        site : '',
        en_question:'',
        zh_question:''
        
      },
      sites:[],
      supervisors:[],
      selectedFile:{
        file:null,
        name:'',
      },
      bulkFile:null,
      
    }
  },
  methods : {
    formSubmit(e){
      e.preventDefault();

      var data = [];

      if(this.temp_site){
          this.temp_site.forEach(site => {
              data.push({
                  site        : site._id,
                  en_question : this.form.en_question,
                  zh_question : this.form.zh_question
              });
          });
      } 

      return this.$store.dispatch(POST_API, {
          data:{
            //  items:this.form
             items : data
          },
          api: '/api/add-inspection-question'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Record Added Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    // this.$router.go(-1);
                    this.$router.push({ name:'safety-inspection-questions' })
                });
                
            }
        });
      
    },

    allSites(){
      return this.$store.dispatch(POST_API, {
           data:{
             role:this.$store.getters.currentUser.role,
             //om_sites:this.$store.getters.currentUser.om_sites,
           },
           api: '/api/all-sites'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.sites = data;
                return this.sites;
            }
        });
    },
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:{name:'custom-forms'},
        text: 'Custom Forms',
      },{
        to:{name:'create-safety-inspection'},
        text:'Quaterly Safety Inspection'
      },{
        to:{name:'safety-inspection-questions'},
        text:'Questions'
      },{
        to:null,
        text:'Add Question',
        active:true
      }];
      return item;
    }
    

    

  },
  mounted(){
    this.allSites();
  }
}
</script>
